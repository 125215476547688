/*
------------------------------
	Impromptu Base Styles
------------------------------
*/
.jqifade{
	position: absolute; 
	background-color: #777777; 
}
div.jqi{ 
	width: 400px; 
	position: absolute; 
	background-color: #ffffff; 
	text-align: left; 
	border: solid 1px #eeeeee;
	border-radius: 6px;
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
	padding: 7px;
}
div.jqi .jqicontainer{ 
}
div.jqi .jqiclose{ 
	position: absolute;
	top: 4px; right: -2px; 
	width: 18px; 
	cursor: default; 
	color: #bbbbbb; 
	font-weight: bold; 
}
div.jqi .jqistate{
	background-color: #fff;
}
div.jqi .jqiparentstate::after{ 
	background-color: #eee;
	opacity: 0.7;
	filter: alpha(opacity=70);
	content: '';
	position: absolute;
	top:0;left:0;bottom:0;right:0;
	border-radius: 6px;
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
}
div.jqi .jqisubstate{ 

	position: absolute;
	top:0;
	left: 20%;
	width: 60%;
	padding: 7px;
	border: solid 1px #eeeeee;
	border-top: none;
	border-radius: 0 0 6px 6px;
	-moz-border-radius: 0 0 6px 6px;
	-webkit-border-radius: 0 0 6px 6px;
}
div.jqi .jqititle{
	padding: 5px 10px;
	border-bottom: solid 1px #eeeeee;
}
div.jqi .jqimessage{ 
	padding: 10px; 
	color: #444444; 
}
div.jqi .jqibuttons{ 
	padding: 5px;
	text-align: right;
}
div.jqi button{ 
	margin: 0 0 0 10px;
}

.jqi .jqiarrow{ position: absolute; height: 0; width:0; line-height: 0; font-size: 0; border: solid 10px transparent;}

.jqi .jqiarrowtl{ left: 10px; top: -20px; border-bottom-color: #ffffff; }
.jqi .jqiarrowtc{ left: 50%; top: -20px; border-bottom-color: #ffffff; margin-left: -10px; }
.jqi .jqiarrowtr{ right: 10px; top: -20px; border-bottom-color: #ffffff; }

.jqi .jqiarrowbl{ left: 10px; bottom: -20px; border-top-color: #ffffff; }
.jqi .jqiarrowbc{ left: 50%; bottom: -20px; border-top-color: #ffffff; margin-left: -10px; }
.jqi .jqiarrowbr{ right: 10px; bottom: -20px; border-top-color: #ffffff; }

.jqi .jqiarrowlt{ left: -20px; top: 10px; border-right-color: #ffffff; }
.jqi .jqiarrowlm{ left: -20px; top: 50%; border-right-color: #ffffff; margin-top: -10px; }
.jqi .jqiarrowlb{ left: -20px; bottom: 10px; border-right-color: #ffffff; }

.jqi .jqiarrowrt{ right: -20px; top: 10px; border-left-color: #ffffff; }
.jqi .jqiarrowrm{ right: -20px; top: 50%; border-left-color: #ffffff; margin-top: -10px; }
.jqi .jqiarrowrb{ right: -20px; bottom: 10px; border-left-color: #ffffff; }

