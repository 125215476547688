// DEVICE
$font-size-base: 14px !default;
// COLOR
$padding-smaller-vertical: 3px !default;
$line-height-smaller: 1.6 !default;
$font-size-smaller: ceil(($font-size-base * 0.85)) !default; // ~12px
$input-height-smaller: (floor($font-size-smaller * $line-height-smaller) + ($padding-smaller-vertical * 2) + 2) !default;
$std-border-color: #ccc;
$pii-link-color-menu: #333;
$pii-link-color: #777;
$pii-link-color-hover: #D2322D;

$icon-font-path: "../lib/bootstrap/fonts/";
$navbar-height: 37px;

$padding-xs-vertical:       3.5px !default;
$padding-xs-horizontal:     7px !default;

$grid-gutter-width:         32px !default;

$nav-link-padding: 10px !default;