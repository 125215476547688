@include input-size('.input-xs', $input-height-smaller, $padding-smaller-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $input-border-radius-small);
.form-group-xs {
  .form-control {
    height: $input-height-smaller;
    padding: $padding-smaller-vertical $padding-small-horizontal;
    font-size: $font-size-small;
    line-height: $line-height-small;
    border-radius: $input-border-radius-small;
  }

  select.form-control {
    height: $input-height-smaller;
    line-height: $input-height-smaller;
  }

  textarea.form-control,
  select[multiple].form-control {
    height: auto;
  }

  .form-control-static {
    height: $input-height-smaller;
    min-height: ($line-height-computed + $font-size-small);
    padding: ($padding-smaller-vertical + 1) $padding-small-horizontal;
    font-size: $font-size-small;
    line-height: $line-height-small;
  }
}

.input-group-xs > .form-control,
.input-group-xs > .input-group-addon,
.input-group-xs > .input-group-btn > .btn {
  @extend .input-xs;

  &:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.checkbox input[type=checkbox], .checkbox-inline input[type=checkbox], .radio input[type=radio], .radio-inline input[type=radio] {
  top: 5px;
  left: 20px;
}

html {
  font-size: 1rem;
}

.input-group {
  &.input-group-xs {
    .input-group-addon {
      padding: 3px 7px;
    }
  }
}

.form-group-sm .form-control {
  &.input-xs {
    height: $input-height-smaller;
    padding: $padding-smaller-vertical $padding-small-horizontal;
    font-size: $font-size-small;
    line-height: $line-height-small;
    border-radius: $input-border-radius-small;
  }
}