/* 
    Document   : design-2013-07-30
    Created on : 30.07.2013, 17:05:15
    Author     : wpfeiffer
    Description:
Alternativer Layout-Entwurf         
Layout und Design fuer die Loginpage und PII Infoboxen
Version 0.1        
*/

/* Gegenentwurf neue Startseite f. PII */

#login_page {
    width: 100%;
    max-width: 450px;
    padding-top: 15px;
    padding-left: 4px;
    padding-right: 4px;
    margin: 0 auto;
    position: relative;
    color: #333;
    font-family: verdana;
    font-size: 12px;
    overflow-x: hidden;
}

#login_mainpart {
    behavior: url(../lib/pie/PIE.htc);
    -moz-border-radius: 20px 20px 0 0;
    -webkit-border-radius: 20px 20px 0 0;
    border-radius: 20px 20px 0 0;
    margin-bottom: 30px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.31) 0px 0px 10px;
    background: #FFF;
    padding: 20px 10px;
}

#login_header {
    height: 100px;
    background-repeat: no-repeat;
}

#maintenance_info {
    text-align: center;
}


#login_footer {
    font-family: Verdana;
    font-size: 11px;
    color: #555;
    text-align: center;
}

.login-infos {
    margin-top: 10px;
}
.login-group {
    padding-top: 5px;
}

.loginSlogan {
    margin-left: 10px;
}

.loginSlogan .slogan {
    margin-bottom: 5px;
}

.loginSlogan .copyright {
    font-size: smaller;
}

.loginSlogan .copyright a {
    color: #000;
}

