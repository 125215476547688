@font-face {
    font-family: 'icomoon';
    src: url('css/fonts/icomoon.eot?o2n3sn');
    src: url('css/fonts/icomoon.eot?#iefixo2n3sn') format('embedded-opentype'),
    url('css/fonts/icomoon.woff?o2n3sn') format('woff'),
    url('css/fonts/icomoon.ttf?o2n3sn') format('truetype'),
    url('css/fonts/icomoon.svg?o2n3sn#icomoon') format('svg');
}

.icomoon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'icomoon';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-home {
    &:before {
        content: "\e601";
    }
}

.icon-home2 {
    &:before {
        content: "\e602";
    }
}

.icon-home3 {
    &:before {
        content: "\e603";
    }
}

.icon-office {
    &:before {
        content: "\e604";
    }
}

.icon-newspaper {
    &:before {
        content: "\e605";
    }
}

.icon-pencil {
    &:before {
        content: "\e606";
    }
}

.icon-pencil2 {
    &:before {
        content: "\e607";
    }
}

.icon-quill {
    &:before {
        content: "\e608";
    }
}

.icon-pen {
    &:before {
        content: "\e609";
    }
}

.icon-blog {
    &:before {
        content: "\e60a";
    }
}

.icon-droplet {
    &:before {
        content: "\e60b";
    }
}

.icon-paint-format {
    &:before {
        content: "\e60c";
    }
}

.icon-image {
    &:before {
        content: "\e60d";
    }
}

.icon-image2 {
    &:before {
        content: "\e60e";
    }
}

.icon-images {
    &:before {
        content: "\e60f";
    }
}

.icon-camera {
    &:before {
        content: "\e610";
    }
}

.icon-music {
    &:before {
        content: "\e611";
    }
}

.icon-headphones {
    &:before {
        content: "\e612";
    }
}

.icon-play {
    &:before {
        content: "\e613";
    }
}

.icon-film {
    &:before {
        content: "\e614";
    }
}

.icon-camera2 {
    &:before {
        content: "\e615";
    }
}

.icon-dice {
    &:before {
        content: "\e616";
    }
}

.icon-pacman {
    &:before {
        content: "\e617";
    }
}

.icon-spades {
    &:before {
        content: "\e618";
    }
}

.icon-clubs {
    &:before {
        content: "\e619";
    }
}

.icon-diamonds {
    &:before {
        content: "\e61a";
    }
}

.icon-pawn {
    &:before {
        content: "\e61b";
    }
}

.icon-bullhorn {
    &:before {
        content: "\e61c";
    }
}

.icon-connection {
    &:before {
        content: "\e61d";
    }
}

.icon-podcast {
    &:before {
        content: "\e61e";
    }
}

.icon-feed {
    &:before {
        content: "\e61f";
    }
}

.icon-book {
    &:before {
        content: "\e620";
    }
}

.icon-books {
    &:before {
        content: "\e621";
    }
}

.icon-library {
    &:before {
        content: "\e622";
    }
}

.icon-file {
    &:before {
        content: "\e623";
    }
}

.icon-profile {
    &:before {
        content: "\e624";
    }
}

.icon-file2 {
    &:before {
        content: "\e625";
    }
}

.icon-file3 {
    &:before {
        content: "\e626";
    }
}

.icon-file4 {
    &:before {
        content: "\e627";
    }
}

.icon-copy {
    &:before {
        content: "\e628";
    }
}

.icon-copy2 {
    &:before {
        content: "\e629";
    }
}

.icon-copy3 {
    &:before {
        content: "\e62a";
    }
}

.icon-paste {
    &:before {
        content: "\e62b";
    }
}

.icon-paste2 {
    &:before {
        content: "\e62c";
    }
}

.icon-paste3 {
    &:before {
        content: "\e62d";
    }
}

.icon-stack {
    &:before {
        content: "\e62e";
    }
}

.icon-folder {
    &:before {
        content: "\e62f";
    }
}

.icon-folder-open {
    &:before {
        content: "\e630";
    }
}

.icon-tag {
    &:before {
        content: "\e631";
    }
}

.icon-tags {
    &:before {
        content: "\e632";
    }
}

.icon-barcode {
    &:before {
        content: "\e633";
    }
}

.icon-qrcode {
    &:before {
        content: "\e634";
    }
}

.icon-ticket {
    &:before {
        content: "\e635";
    }
}

.icon-cart {
    &:before {
        content: "\e636";
    }
}

.icon-cart2 {
    &:before {
        content: "\e637";
    }
}

.icon-cart3 {
    &:before {
        content: "\e638";
    }
}

.icon-coin {
    &:before {
        content: "\e639";
    }
}

.icon-credit {
    &:before {
        content: "\e63a";
    }
}

.icon-calculate {
    &:before {
        content: "\e63b";
    }
}

.icon-support {
    &:before {
        content: "\e63c";
    }
}

.icon-phone {
    &:before {
        content: "\e63d";
    }
}

.icon-phone-hang-up {
    &:before {
        content: "\e63e";
    }
}

.icon-address-book {
    &:before {
        content: "\e63f";
    }
}

.icon-notebook {
    &:before {
        content: "\e640";
    }
}

.icon-envelope {
    &:before {
        content: "\e641";
    }
}

.icon-pushpin {
    &:before {
        content: "\e642";
    }
}

.icon-location {
    &:before {
        content: "\e643";
    }
}

.icon-location2 {
    &:before {
        content: "\e644";
    }
}

.icon-compass {
    &:before {
        content: "\e645";
    }
}

.icon-map {
    &:before {
        content: "\e646";
    }
}

.icon-map2 {
    &:before {
        content: "\e647";
    }
}

.icon-history {
    &:before {
        content: "\e648";
    }
}

.icon-clock {
    &:before {
        content: "\e649";
    }
}

.icon-clock2 {
    &:before {
        content: "\e64a";
    }
}

.icon-alarm {
    &:before {
        content: "\e64b";
    }
}

.icon-alarm2 {
    &:before {
        content: "\e64c";
    }
}

.icon-bell {
    &:before {
        content: "\e64d";
    }
}

.icon-stopwatch {
    &:before {
        content: "\e64e";
    }
}

.icon-calendar {
    &:before {
        content: "\e64f";
    }
}

.icon-calendar2 {
    &:before {
        content: "\e650";
    }
}

.icon-print {
    &:before {
        content: "\e651";
    }
}

.icon-keyboard {
    &:before {
        content: "\e652";
    }
}

.icon-screen {
    &:before {
        content: "\e653";
    }
}

.icon-laptop {
    &:before {
        content: "\e654";
    }
}

.icon-mobile {
    &:before {
        content: "\e655";
    }
}

.icon-mobile2 {
    &:before {
        content: "\e656";
    }
}

.icon-tablet {
    &:before {
        content: "\e657";
    }
}

.icon-tv {
    &:before {
        content: "\e658";
    }
}

.icon-cabinet {
    &:before {
        content: "\e659";
    }
}

.icon-drawer {
    &:before {
        content: "\e65a";
    }
}

.icon-drawer2 {
    &:before {
        content: "\e65b";
    }
}

.icon-drawer3 {
    &:before {
        content: "\e65c";
    }
}

.icon-box-add {
    &:before {
        content: "\e65d";
    }
}

.icon-box-remove {
    &:before {
        content: "\e65e";
    }
}

.icon-download {
    &:before {
        content: "\e65f";
    }
}

.icon-upload {
    &:before {
        content: "\e660";
    }
}

.icon-disk {
    &:before {
        content: "\e661";
    }
}

.icon-storage {
    &:before {
        content: "\e662";
    }
}

.icon-undo {
    &:before {
        content: "\e663";
    }
}

.icon-redo {
    &:before {
        content: "\e664";
    }
}

.icon-flip {
    &:before {
        content: "\e665";
    }
}

.icon-flip2 {
    &:before {
        content: "\e666";
    }
}

.icon-undo2 {
    &:before {
        content: "\e667";
    }
}

.icon-redo2 {
    &:before {
        content: "\e668";
    }
}

.icon-forward {
    &:before {
        content: "\e669";
    }
}

.icon-reply {
    &:before {
        content: "\e66a";
    }
}

.icon-bubble {
    &:before {
        content: "\e66b";
    }
}

.icon-bubbles {
    &:before {
        content: "\e66c";
    }
}

.icon-bubbles2 {
    &:before {
        content: "\e66d";
    }
}

.icon-bubble2 {
    &:before {
        content: "\e66e";
    }
}

.icon-bubbles3 {
    &:before {
        content: "\e66f";
    }
}

.icon-bubbles4 {
    &:before {
        content: "\e670";
    }
}

.icon-user {
    &:before {
        content: "\e671";
    }
}

.icon-users {
    &:before {
        content: "\e672";
    }
}

.icon-user2 {
    &:before {
        content: "\e673";
    }
}

.icon-users2 {
    &:before {
        content: "\e674";
    }
}

.icon-user3 {
    &:before {
        content: "\e675";
    }
}

.icon-user4 {
    &:before {
        content: "\e676";
    }
}

.icon-quotes-left {
    &:before {
        content: "\e677";
    }
}

.icon-busy {
    &:before {
        content: "\e678";
    }
}

.icon-spinner {
    &:before {
        content: "\e679";
    }
}

.icon-spinner2 {
    &:before {
        content: "\e67a";
    }
}

.icon-spinner3 {
    &:before {
        content: "\e67b";
    }
}

.icon-spinner4 {
    &:before {
        content: "\e67c";
    }
}

.icon-spinner5 {
    &:before {
        content: "\e67d";
    }
}

.icon-spinner6 {
    &:before {
        content: "\e67e";
    }
}

.icon-binoculars {
    &:before {
        content: "\e67f";
    }
}

.icon-search {
    &:before {
        content: "\e680";
    }
}

.icon-zoom-in {
    &:before {
        content: "\e681";
    }
}

.icon-zoom-out {
    &:before {
        content: "\e682";
    }
}

.icon-expand {
    &:before {
        content: "\e683";
    }
}

.icon-contract {
    &:before {
        content: "\e684";
    }
}

.icon-expand2 {
    &:before {
        content: "\e685";
    }
}

.icon-contract2 {
    &:before {
        content: "\e686";
    }
}

.icon-key {
    &:before {
        content: "\e687";
    }
}

.icon-key2 {
    &:before {
        content: "\e688";
    }
}

.icon-lock {
    &:before {
        content: "\e689";
    }
}

.icon-lock2 {
    &:before {
        content: "\e68a";
    }
}

.icon-unlocked {
    &:before {
        content: "\e68b";
    }
}

.icon-wrench {
    &:before {
        content: "\e68c";
    }
}

.icon-settings {
    &:before {
        content: "\e68d";
    }
}

.icon-equalizer {
    &:before {
        content: "\e68e";
    }
}

.icon-cog {
    &:before {
        content: "\e68f";
    }
}

.icon-cogs {
    &:before {
        content: "\e690";
    }
}

.icon-cog2 {
    &:before {
        content: "\e691";
    }
}

.icon-hammer {
    &:before {
        content: "\e692";
    }
}

.icon-wand {
    &:before {
        content: "\e693";
    }
}

.icon-aid {
    &:before {
        content: "\e694";
    }
}

.icon-bug {
    &:before {
        content: "\e695";
    }
}

.icon-pie {
    &:before {
        content: "\e696";
    }
}

.icon-stats {
    &:before {
        content: "\e697";
    }
}

.icon-bars {
    &:before {
        content: "\e698";
    }
}

.icon-bars2 {
    &:before {
        content: "\e699";
    }
}

.icon-gift {
    &:before {
        content: "\e69a";
    }
}

.icon-trophy {
    &:before {
        content: "\e69b";
    }
}

.icon-glass {
    &:before {
        content: "\e69c";
    }
}

.icon-mug {
    &:before {
        content: "\e69d";
    }
}

.icon-food {
    &:before {
        content: "\e69e";
    }
}

.icon-leaf {
    &:before {
        content: "\e69f";
    }
}

.icon-rocket {
    &:before {
        content: "\e6a0";
    }
}

.icon-meter {
    &:before {
        content: "\e6a1";
    }
}

.icon-meter2 {
    &:before {
        content: "\e6a2";
    }
}

.icon-dashboard {
    &:before {
        content: "\e6a3";
    }
}

.icon-hammer2 {
    &:before {
        content: "\e6a4";
    }
}

.icon-fire {
    &:before {
        content: "\e6a5";
    }
}

.icon-lab {
    &:before {
        content: "\e6a6";
    }
}

.icon-magnet {
    &:before {
        content: "\e6a7";
    }
}

.icon-remove {
    &:before {
        content: "\e6a8";
    }
}

.icon-remove2 {
    &:before {
        content: "\e6a9";
    }
}

.icon-briefcase {
    &:before {
        content: "\e6aa";
    }
}

.icon-airplane {
    &:before {
        content: "\e6ab";
    }
}

.icon-truck {
    &:before {
        content: "\e6ac";
    }
}

.icon-road {
    &:before {
        content: "\e6ad";
    }
}

.icon-accessibility {
    &:before {
        content: "\e6ae";
    }
}

.icon-target {
    &:before {
        content: "\e6af";
    }
}

.icon-shield {
    &:before {
        content: "\e6b0";
    }
}

.icon-lightning {
    &:before {
        content: "\e6b1";
    }
}

.icon-switch {
    &:before {
        content: "\e6b2";
    }
}

.icon-power-cord {
    &:before {
        content: "\e6b3";
    }
}

.icon-signup {
    &:before {
        content: "\e6b4";
    }
}

.icon-list {
    &:before {
        content: "\e6b5";
    }
}

.icon-list2 {
    &:before {
        content: "\e6b6";
    }
}

.icon-numbered-list {
    &:before {
        content: "\e6b7";
    }
}

.icon-menu {
    &:before {
        content: "\e6b8";
    }
}

.icon-menu2 {
    &:before {
        content: "\e6b9";
    }
}

.icon-tree {
    &:before {
        content: "\e6ba";
    }
}

.icon-cloud {
    &:before {
        content: "\e6bb";
    }
}

.icon-cloud-download {
    &:before {
        content: "\e6bc";
    }
}

.icon-cloud-upload {
    &:before {
        content: "\e6bd";
    }
}

.icon-download2 {
    &:before {
        content: "\e6be";
    }
}

.icon-upload2 {
    &:before {
        content: "\e6bf";
    }
}

.icon-download3 {
    &:before {
        content: "\e6c0";
    }
}

.icon-upload3 {
    &:before {
        content: "\e6c1";
    }
}

.icon-globe {
    &:before {
        content: "\e6c2";
    }
}

.icon-earth {
    &:before {
        content: "\e6c3";
    }
}

.icon-link {
    &:before {
        content: "\e6c4";
    }
}

.icon-flag {
    &:before {
        content: "\e6c5";
    }
}

.icon-attachment {
    &:before {
        content: "\e6c6";
    }
}

.icon-eye {
    &:before {
        content: "\e6c7";
    }
}

.icon-eye-blocked {
    &:before {
        content: "\e6c8";
    }
}

.icon-eye2 {
    &:before {
        content: "\e6c9";
    }
}

.icon-bookmark {
    &:before {
        content: "\e6ca";
    }
}

.icon-bookmarks {
    &:before {
        content: "\e6cb";
    }
}

.icon-brightness-medium {
    &:before {
        content: "\e6cc";
    }
}

.icon-brightness-contrast {
    &:before {
        content: "\e6cd";
    }
}

.icon-contrast {
    &:before {
        content: "\e6ce";
    }
}

.icon-star {
    &:before {
        content: "\e6cf";
    }
}

.icon-star2 {
    &:before {
        content: "\e6d0";
    }
}

.icon-star3 {
    &:before {
        content: "\e6d1";
    }
}

.icon-heart {
    &:before {
        content: "\e6d2";
    }
}

.icon-heart2 {
    &:before {
        content: "\e6d3";
    }
}

.icon-heart-broken {
    &:before {
        content: "\e6d4";
    }
}

.icon-thumbs-up {
    &:before {
        content: "\e6d5";
    }
}

.icon-thumbs-up2 {
    &:before {
        content: "\e6d6";
    }
}

.icon-happy {
    &:before {
        content: "\e6d7";
    }
}

.icon-happy2 {
    &:before {
        content: "\e6d8";
    }
}

.icon-smiley {
    &:before {
        content: "\e6d9";
    }
}

.icon-smiley2 {
    &:before {
        content: "\e6da";
    }
}

.icon-tongue {
    &:before {
        content: "\e6db";
    }
}

.icon-tongue2 {
    &:before {
        content: "\e6dc";
    }
}

.icon-sad {
    &:before {
        content: "\e6dd";
    }
}

.icon-sad2 {
    &:before {
        content: "\e6de";
    }
}

.icon-wink {
    &:before {
        content: "\e6df";
    }
}

.icon-wink2 {
    &:before {
        content: "\e6e0";
    }
}

.icon-grin {
    &:before {
        content: "\e6e1";
    }
}

.icon-grin2 {
    &:before {
        content: "\e6e2";
    }
}

.icon-cool {
    &:before {
        content: "\e6e3";
    }
}

.icon-cool2 {
    &:before {
        content: "\e6e4";
    }
}

.icon-angry {
    &:before {
        content: "\e6e5";
    }
}

.icon-angry2 {
    &:before {
        content: "\e6e6";
    }
}

.icon-evil {
    &:before {
        content: "\e6e7";
    }
}

.icon-evil2 {
    &:before {
        content: "\e6e8";
    }
}

.icon-shocked {
    &:before {
        content: "\e6e9";
    }
}

.icon-shocked2 {
    &:before {
        content: "\e6ea";
    }
}

.icon-confused {
    &:before {
        content: "\e6eb";
    }
}

.icon-confused2 {
    &:before {
        content: "\e6ec";
    }
}

.icon-neutral {
    &:before {
        content: "\e6ed";
    }
}

.icon-neutral2 {
    &:before {
        content: "\e6ee";
    }
}

.icon-wondering {
    &:before {
        content: "\e6ef";
    }
}

.icon-wondering2 {
    &:before {
        content: "\e6f0";
    }
}

.icon-point-up {
    &:before {
        content: "\e6f1";
    }
}

.icon-point-right {
    &:before {
        content: "\e6f2";
    }
}

.icon-point-down {
    &:before {
        content: "\e6f3";
    }
}

.icon-point-left {
    &:before {
        content: "\e6f4";
    }
}

.icon-warning {
    &:before {
        content: "\e6f5";
    }
}

.icon-notification {
    &:before {
        content: "\e6f6";
    }
}

.icon-question {
    &:before {
        content: "\e6f7";
    }
}

.icon-info {
    &:before {
        content: "\e6f8";
    }
}

.icon-info2 {
    &:before {
        content: "\e6f9";
    }
}

.icon-blocked {
    &:before {
        content: "\e6fa";
    }
}

.icon-cancel-circle {
    &:before {
        content: "\e6fb";
    }
}

.icon-checkmark-circle {
    &:before {
        content: "\e6fc";
    }
}

.icon-spam {
    &:before {
        content: "\e6fd";
    }
}

.icon-close {
    &:before {
        content: "\e6fe";
    }
}

.icon-checkmark {
    &:before {
        content: "\e6ff";
    }
}

.icon-checkmark2 {
    &:before {
        content: "\e700";
    }
}

.icon-spell-check {
    &:before {
        content: "\e701";
    }
}

.icon-minus {
    &:before {
        content: "\e702";
    }
}

.icon-plus {
    &:before {
        content: "\e703";
    }
}

.icon-enter {
    &:before {
        content: "\e704";
    }
}

.icon-exit {
    &:before {
        content: "\e705";
    }
}

.icon-play2 {
    &:before {
        content: "\e706";
    }
}

.icon-pause {
    &:before {
        content: "\e707";
    }
}

.icon-stop {
    &:before {
        content: "\e708";
    }
}

.icon-backward {
    &:before {
        content: "\e709";
    }
}

.icon-forward2 {
    &:before {
        content: "\e70a";
    }
}

.icon-play3 {
    &:before {
        content: "\e70b";
    }
}

.icon-pause2 {
    &:before {
        content: "\e70c";
    }
}

.icon-stop2 {
    &:before {
        content: "\e70d";
    }
}

.icon-backward2 {
    &:before {
        content: "\e70e";
    }
}

.icon-forward3 {
    &:before {
        content: "\e70f";
    }
}

.icon-first {
    &:before {
        content: "\e710";
    }
}

.icon-last {
    &:before {
        content: "\e711";
    }
}

.icon-previous {
    &:before {
        content: "\e712";
    }
}

.icon-next {
    &:before {
        content: "\e713";
    }
}

.icon-eject {
    &:before {
        content: "\e714";
    }
}

.icon-volume-high {
    &:before {
        content: "\e715";
    }
}

.icon-volume-medium {
    &:before {
        content: "\e716";
    }
}

.icon-volume-low {
    &:before {
        content: "\e717";
    }
}

.icon-volume-mute {
    &:before {
        content: "\e718";
    }
}

.icon-volume-mute2 {
    &:before {
        content: "\e719";
    }
}

.icon-volume-increase {
    &:before {
        content: "\e71a";
    }
}

.icon-volume-decrease {
    &:before {
        content: "\e71b";
    }
}

.icon-loop {
    &:before {
        content: "\e71c";
    }
}

.icon-loop2 {
    &:before {
        content: "\e71d";
    }
}

.icon-loop3 {
    &:before {
        content: "\e71e";
    }
}

.icon-shuffle {
    &:before {
        content: "\e71f";
    }
}

.icon-arrow-up-left {
    &:before {
        content: "\e720";
    }
}

.icon-arrow-up {
    &:before {
        content: "\e721";
    }
}

.icon-arrow-up-right {
    &:before {
        content: "\e722";
    }
}

.icon-arrow-right {
    &:before {
        content: "\e723";
    }
}

.icon-arrow-down-right {
    &:before {
        content: "\e724";
    }
}

.icon-arrow-down {
    &:before {
        content: "\e725";
    }
}

.icon-arrow-down-left {
    &:before {
        content: "\e726";
    }
}

.icon-arrow-left {
    &:before {
        content: "\e727";
    }
}

.icon-arrow-up-left2 {
    &:before {
        content: "\e728";
    }
}

.icon-arrow-up2 {
    &:before {
        content: "\e729";
    }
}

.icon-arrow-up-right2 {
    &:before {
        content: "\e72a";
    }
}

.icon-arrow-right2 {
    &:before {
        content: "\e72b";
    }
}

.icon-arrow-down-right2 {
    &:before {
        content: "\e72c";
    }
}

.icon-arrow-down2 {
    &:before {
        content: "\e72d";
    }
}

.icon-arrow-down-left2 {
    &:before {
        content: "\e72e";
    }
}

.icon-arrow-left2 {
    &:before {
        content: "\e72f";
    }
}

.icon-arrow-up-left3 {
    &:before {
        content: "\e730";
    }
}

.icon-arrow-up3 {
    &:before {
        content: "\e731";
    }
}

.icon-arrow-up-right3 {
    &:before {
        content: "\e732";
    }
}

.icon-arrow-right3 {
    &:before {
        content: "\e733";
    }
}

.icon-arrow-down-right3 {
    &:before {
        content: "\e734";
    }
}

.icon-arrow-down3 {
    &:before {
        content: "\e735";
    }
}

.icon-arrow-down-left3 {
    &:before {
        content: "\e736";
    }
}

.icon-arrow-left3 {
    &:before {
        content: "\e737";
    }
}

.icon-tab {
    &:before {
        content: "\e738";
    }
}

.icon-checkbox-checked {
    &:before {
        content: "\e739";
    }
}

.icon-checkbox-unchecked {
    &:before {
        content: "\e73a";
    }
}

.icon-checkbox-partial {
    &:before {
        content: "\e73b";
    }
}

.icon-radio-checked {
    &:before {
        content: "\e73c";
    }
}

.icon-radio-unchecked {
    &:before {
        content: "\e73d";
    }
}

.icon-crop {
    &:before {
        content: "\e73e";
    }
}

.icon-scissors {
    &:before {
        content: "\e73f";
    }
}

.icon-filter {
    &:before {
        content: "\e740";
    }
}

.icon-filter2 {
    &:before {
        content: "\e741";
    }
}

.icon-font {
    &:before {
        content: "\e742";
    }
}

.icon-text-height {
    &:before {
        content: "\e743";
    }
}

.icon-text-width {
    &:before {
        content: "\e744";
    }
}

.icon-bold {
    &:before {
        content: "\e745";
    }
}

.icon-underline {
    &:before {
        content: "\e746";
    }
}

.icon-italic {
    &:before {
        content: "\e747";
    }
}

.icon-strikethrough {
    &:before {
        content: "\e748";
    }
}

.icon-omega {
    &:before {
        content: "\e749";
    }
}

.icon-sigma {
    &:before {
        content: "\e74a";
    }
}

.icon-table {
    &:before {
        content: "\e74b";
    }
}

.icon-table2 {
    &:before {
        content: "\e74c";
    }
}

.icon-insert-template {
    &:before {
        content: "\e74d";
    }
}

.icon-pilcrow {
    &:before {
        content: "\e74e";
    }
}

.icon-left-toright {
    &:before {
        content: "\e74f";
    }
}

.icon-right-toleft {
    &:before {
        content: "\e750";
    }
}

.icon-paragraph-left {
    &:before {
        content: "\e751";
    }
}

.icon-paragraph-center {
    &:before {
        content: "\e752";
    }
}

.icon-paragraph-right {
    &:before {
        content: "\e753";
    }
}

.icon-paragraph-justify {
    &:before {
        content: "\e754";
    }
}

.icon-paragraph-left2 {
    &:before {
        content: "\e755";
    }
}

.icon-paragraph-center2 {
    &:before {
        content: "\e756";
    }
}

.icon-paragraph-right2 {
    &:before {
        content: "\e757";
    }
}

.icon-paragraph-justify2 {
    &:before {
        content: "\e758";
    }
}

.icon-indent-increase {
    &:before {
        content: "\e759";
    }
}

.icon-indent-decrease {
    &:before {
        content: "\e75a";
    }
}

.icon-new-tab {
    &:before {
        content: "\e75b";
    }
}

.icon-embed {
    &:before {
        content: "\e75c";
    }
}

.icon-code {
    &:before {
        content: "\e75d";
    }
}

.icon-console {
    &:before {
        content: "\e75e";
    }
}

.icon-share {
    &:before {
        content: "\e75f";
    }
}

.icon-mail {
    &:before {
        content: "\e760";
    }
}

.icon-mail2 {
    &:before {
        content: "\e761";
    }
}

.icon-mail3 {
    &:before {
        content: "\e762";
    }
}

.icon-mail4 {
    &:before {
        content: "\e763";
    }
}

.icon-google {
    &:before {
        content: "\e764";
    }
}

.icon-google-plus {
    &:before {
        content: "\e765";
    }
}

.icon-google-plus2 {
    &:before {
        content: "\e766";
    }
}

.icon-google-plus3 {
    &:before {
        content: "\e767";
    }
}

.icon-google-plus4 {
    &:before {
        content: "\e768";
    }
}

.icon-google-drive {
    &:before {
        content: "\e769";
    }
}

.icon-facebook {
    &:before {
        content: "\e76a";
    }
}

.icon-facebook2 {
    &:before {
        content: "\e76b";
    }
}

.icon-facebook3 {
    &:before {
        content: "\e76c";
    }
}

.icon-instagram {
    &:before {
        content: "\e76d";
    }
}

.icon-twitter {
    &:before {
        content: "\e76e";
    }
}

.icon-twitter2 {
    &:before {
        content: "\e76f";
    }
}

.icon-twitter3 {
    &:before {
        content: "\e770";
    }
}

.icon-feed2 {
    &:before {
        content: "\e771";
    }
}

.icon-feed3 {
    &:before {
        content: "\e772";
    }
}

.icon-feed4 {
    &:before {
        content: "\e773";
    }
}

.icon-youtube {
    &:before {
        content: "\e774";
    }
}

.icon-youtube2 {
    &:before {
        content: "\e775";
    }
}

.icon-vimeo {
    &:before {
        content: "\e776";
    }
}

.icon-vimeo2 {
    &:before {
        content: "\e777";
    }
}

.icon-vimeo3 {
    &:before {
        content: "\e778";
    }
}

.icon-lanyrd {
    &:before {
        content: "\e779";
    }
}

.icon-flickr {
    &:before {
        content: "\e77a";
    }
}

.icon-flickr2 {
    &:before {
        content: "\e77b";
    }
}

.icon-flickr3 {
    &:before {
        content: "\e77c";
    }
}

.icon-flickr4 {
    &:before {
        content: "\e77d";
    }
}

.icon-picassa {
    &:before {
        content: "\e77e";
    }
}

.icon-picassa2 {
    &:before {
        content: "\e77f";
    }
}

.icon-dribbble {
    &:before {
        content: "\e780";
    }
}

.icon-dribbble2 {
    &:before {
        content: "\e781";
    }
}

.icon-dribbble3 {
    &:before {
        content: "\e782";
    }
}

.icon-forrst {
    &:before {
        content: "\e783";
    }
}

.icon-forrst2 {
    &:before {
        content: "\e784";
    }
}

.icon-deviantart {
    &:before {
        content: "\e785";
    }
}

.icon-deviantart2 {
    &:before {
        content: "\e786";
    }
}

.icon-steam {
    &:before {
        content: "\e787";
    }
}

.icon-steam2 {
    &:before {
        content: "\e788";
    }
}

.icon-github {
    &:before {
        content: "\e789";
    }
}

.icon-github2 {
    &:before {
        content: "\e78a";
    }
}

.icon-github3 {
    &:before {
        content: "\e78b";
    }
}

.icon-github4 {
    &:before {
        content: "\e78c";
    }
}

.icon-github5 {
    &:before {
        content: "\e78d";
    }
}

.icon-wordpress {
    &:before {
        content: "\e78e";
    }
}

.icon-wordpress2 {
    &:before {
        content: "\e78f";
    }
}

.icon-joomla {
    &:before {
        content: "\e790";
    }
}

.icon-blogger {
    &:before {
        content: "\e791";
    }
}

.icon-blogger2 {
    &:before {
        content: "\e792";
    }
}

.icon-tumblr {
    &:before {
        content: "\e793";
    }
}

.icon-tumblr2 {
    &:before {
        content: "\e794";
    }
}

.icon-yahoo {
    &:before {
        content: "\e795";
    }
}

.icon-tux {
    &:before {
        content: "\e796";
    }
}

.icon-apple {
    &:before {
        content: "\e797";
    }
}

.icon-finder {
    &:before {
        content: "\e798";
    }
}

.icon-android {
    &:before {
        content: "\e799";
    }
}

.icon-windows {
    &:before {
        content: "\e79a";
    }
}

.icon-windows8 {
    &:before {
        content: "\e79b";
    }
}

.icon-soundcloud {
    &:before {
        content: "\e79c";
    }
}

.icon-soundcloud2 {
    &:before {
        content: "\e79d";
    }
}

.icon-skype {
    &:before {
        content: "\e79e";
    }
}

.icon-reddit {
    &:before {
        content: "\e79f";
    }
}

.icon-linkedin {
    &:before {
        content: "\e7a0";
    }
}

.icon-lastfm {
    &:before {
        content: "\e7a1";
    }
}

.icon-lastfm2 {
    &:before {
        content: "\e7a2";
    }
}

.icon-delicious {
    &:before {
        content: "\e7a3";
    }
}

.icon-stumbleupon {
    &:before {
        content: "\e7a4";
    }
}

.icon-stumbleupon2 {
    &:before {
        content: "\e7a5";
    }
}

.icon-stackoverflow {
    &:before {
        content: "\e7a6";
    }
}

.icon-pinterest {
    &:before {
        content: "\e7a7";
    }
}

.icon-pinterest2 {
    &:before {
        content: "\e7a8";
    }
}

.icon-xing {
    &:before {
        content: "\e7a9";
    }
}

.icon-xing2 {
    &:before {
        content: "\e7aa";
    }
}

.icon-flattr {
    &:before {
        content: "\e7ab";
    }
}

.icon-foursquare {
    &:before {
        content: "\e7ac";
    }
}

.icon-foursquare2 {
    &:before {
        content: "\e7ad";
    }
}

.icon-paypal {
    &:before {
        content: "\e7ae";
    }
}

.icon-paypal2 {
    &:before {
        content: "\e7af";
    }
}

.icon-paypal3 {
    &:before {
        content: "\e7b0";
    }
}

.icon-yelp {
    &:before {
        content: "\e7b1";
    }
}

.icon-libreoffice {
    &:before {
        content: "\e7b2";
    }
}

.icon-file-pdf {
    &:before {
        content: "\e7b3";
    }
}

.icon-file-openoffice {
    &:before {
        content: "\e7b4";
    }
}

.icon-file-word {
    &:before {
        content: "\e7b5";
    }
}

.icon-file-excel {
    &:before {
        content: "\e600";
    }
}

.icon-file-zip {
    &:before {
        content: "\e7b6";
    }
}

.icon-file-powerpoint {
    &:before {
        content: "\e7b7";
    }
}

.icon-file-xml {
    &:before {
        content: "\e7b8";
    }
}

.icon-file-css {
    &:before {
        content: "\e7b9";
    }
}

.icon-html5 {
    &:before {
        content: "\e7ba";
    }
}

.icon-html52 {
    &:before {
        content: "\e7bb";
    }
}

.icon-css3 {
    &:before {
        content: "\e7bc";
    }
}

.icon-chrome {
    &:before {
        content: "\e7bd";
    }
}

.icon-firefox {
    &:before {
        content: "\e7be";
    }
}

.icon-IE {
    &:before {
        content: "\e7bf";
    }
}

.icon-opera {
    &:before {
        content: "\e7c0";
    }
}

.icon-safari {
    &:before {
        content: "\e7c1";
    }
}

.icon-IcoMoon {
    &:before {
        content: "\e7c2";
    }
}