@mixin erstelle-mandanten($mandant-name) {
  body.#{$mandant-name} {
    #login_header {
      background: url('../styles_mandanten/#{$mandant-name}/logo.png') 15px top no-repeat;
    }

    #top #logo {
      background: url('../styles_mandanten/#{$mandant-name}/logo.png') center top no-repeat;
    }
  }
}

/** GENERATE MANDANTEN START */
@include erstelle-mandanten('hbwb'); /* Mandant 1*/
@include erstelle-mandanten('skwb'); /* Mandant 2*/
@include erstelle-mandanten('botr'); /* Mandant 3*/
@include erstelle-mandanten('hbth'); /* Mandant 5*/
@include erstelle-mandanten('tuba'); /* Mandant 6*/
@include erstelle-mandanten('hbwf'); /* Mandant 7*/
@include erstelle-mandanten('hbsh'); /* Mandant 8*/
@include erstelle-mandanten('hbnb'); /* Mandant 9*/
@include erstelle-mandanten('hbsb'); /* Mandant 10*/
@include erstelle-mandanten('hbsn'); /* Mandant 11*/
@include erstelle-mandanten('hbst'); /* Mandant 12*/
@include erstelle-mandanten('kabw'); /* Mandant 15*/
@include erstelle-mandanten('lawb'); /* Mandant 16*/
@include erstelle-mandanten('gobw'); /* Mandant 17*/
@include erstelle-mandanten('swwb'); /* Mandant 18*/
@include erstelle-mandanten('swbd'); /* Mandant 19*/
@include erstelle-mandanten('swba'); /* Mandant 20*/
@include erstelle-mandanten('hbhh'); /* Mandant 22*/
@include erstelle-mandanten('kaba'); /* Mandant 23*/
@include erstelle-mandanten('sksb'); /* Mandant 24*/
@include erstelle-mandanten('lani'); /* Mandant 25*/
@include erstelle-mandanten('tzbw'); /* Mandant 26*/
@include erstelle-mandanten('hbrh'); /* Mandant 27*/
@include erstelle-mandanten('hbsl'); /* Mandant 28*/
@include erstelle-mandanten('brwb'); /* Mandant 30*/
@include erstelle-mandanten('lahe'); /* Mandant 31*/
@include erstelle-mandanten('hbbw'); /* Mandant 32*/
@include erstelle-mandanten('tzni'); /* Mandant 33*/
@include erstelle-mandanten('hbpf'); /* Mandant 34*/
@include erstelle-mandanten('hb4a'); /* Mandant 39*/
@include erstelle-mandanten('hbrp'); /* Mandant 40*/
@include erstelle-mandanten('rabd'); /* Mandant 42*/
@include erstelle-mandanten('hbhs'); /* Mandant 43*/
@include erstelle-mandanten('hblu'); /* Mandant 45*/
@include erstelle-mandanten('rawb'); /* Mandant 47*/
@include erstelle-mandanten('hbdv'); /* Mandant 48*/
@include erstelle-mandanten('hobe'); /* Mandant 49*/
@include erstelle-mandanten('hohh'); /* Mandant 50*/
@include erstelle-mandanten('raba'); /* Mandant 51*/
@include erstelle-mandanten('bowb'); /* Mandant 52*/
@include erstelle-mandanten('labd'); /* Mandant 53*/
@include erstelle-mandanten('laba'); /* Mandant 54*/
@include erstelle-mandanten('lano'); /* Mandant 55*/
@include erstelle-mandanten('lawf'); /* Mandant 56*/
@include erstelle-mandanten('labe'); /* Mandant 57*/
@include erstelle-mandanten('lasn'); /* Mandant 58*/
@include erstelle-mandanten('last'); /* Mandant 59*/
@include erstelle-mandanten('labb'); /* Mandant 60*/
@include erstelle-mandanten('lash'); /* Mandant 61*/
@include erstelle-mandanten('lath'); /* Mandant 62*/
@include erstelle-mandanten('lahh'); /* Mandant 63*/
@include erstelle-mandanten('lamv'); /* Mandant 64*/
@include erstelle-mandanten('ladv'); /* Mandant 65*/
@include erstelle-mandanten('kadv'); /* Mandant 67*/
@include erstelle-mandanten('lahb'); /* Mandant 68*/
@include erstelle-mandanten('larl'); /* Mandant 69*/
@include erstelle-mandanten('lapf'); /* Mandant 70*/
@include erstelle-mandanten('larh'); /* Mandant 71*/
@include erstelle-mandanten('lasl'); /* Mandant 72*/
@include erstelle-mandanten('rtdv'); /* Mandant 73*/
@include erstelle-mandanten('kahe'); /* Mandant 74*/
@include erstelle-mandanten('kasl'); /* Mandant 75*/
@include erstelle-mandanten('kapf'); /* Mandant 76*/
@include erstelle-mandanten('karh'); /* Mandant 77*/
@include erstelle-mandanten('karl'); /* Mandant 78*/
@include erstelle-mandanten('kanw'); /* Mandant 79*/
@include erstelle-mandanten('kani'); /* Mandant 80*/
@include erstelle-mandanten('kahh'); /* Mandant 82*/
@include erstelle-mandanten('kash'); /* Mandant 83*/
@include erstelle-mandanten('kamv'); /* Mandant 84*/
@include erstelle-mandanten('kabb'); /* Mandant 85*/
@include erstelle-mandanten('kabe'); /* Mandant 86*/
@include erstelle-mandanten('kast'); /* Mandant 87*/
@include erstelle-mandanten('kasn'); /* Mandant 88*/
@include erstelle-mandanten('kath'); /* Mandant 89*/
@include erstelle-mandanten('hoau'); /* Mandant 90*/
@include erstelle-mandanten('lsru'); /* Mandant 91*/
@include erstelle-mandanten('lsas'); /* Mandant 92*/
@include erstelle-mandanten('radv'); /* Mandant 93*/
@include erstelle-mandanten('jist'); /* Mandant 94*/
@include erstelle-mandanten('swnw'); /* Mandant 95*/
@include erstelle-mandanten('swsn'); /* Mandant 96*/
@include erstelle-mandanten('swbe'); /* Mandant 97*/
@include erstelle-mandanten('bbrp'); /* Mandant 98*/
@include erstelle-mandanten('tadv'); /* Mandant 100*/
@include erstelle-mandanten('tabw'); /* Mandant 101*/
@include erstelle-mandanten('taba'); /* Mandant 102*/
@include erstelle-mandanten('tabe'); /* Mandant 103*/
@include erstelle-mandanten('tabb'); /* Mandant 104*/
@include erstelle-mandanten('tahb'); /* Mandant 105*/
@include erstelle-mandanten('tahh'); /* Mandant 106*/
@include erstelle-mandanten('tahe'); /* Mandant 107*/
@include erstelle-mandanten('tamv'); /* Mandant 108*/
@include erstelle-mandanten('tani'); /* Mandant 109*/
@include erstelle-mandanten('tanw'); /* Mandant 110*/
@include erstelle-mandanten('tarp'); /* Mandant 111*/
@include erstelle-mandanten('tasl'); /* Mandant 112*/
@include erstelle-mandanten('tasn'); /* Mandant 113*/
@include erstelle-mandanten('tast'); /* Mandant 114*/
@include erstelle-mandanten('tash'); /* Mandant 115*/
@include erstelle-mandanten('tath'); /* Mandant 116*/
@include erstelle-mandanten('hbni'); /* Mandant 120*/
@include erstelle-mandanten('hbmv'); /* Mandant 121*/
@include erstelle-mandanten('hbbb'); /* Mandant 122*/
@include erstelle-mandanten('hbbe'); /* Mandant 123*/
@include erstelle-mandanten('hbhe'); /* Mandant 124*/
@include erstelle-mandanten('hbno'); /* Mandant 125*/
@include erstelle-mandanten('hbrl'); /* Mandant 126*/
@include erstelle-mandanten('hbba'); /* Mandant 127*/
@include erstelle-mandanten('kahb'); /* Mandant 128*/
@include erstelle-mandanten('swrl'); /* Mandant 129*/
@include erstelle-mandanten('jith'); /* Mandant 130*/
@include erstelle-mandanten('jisn'); /* Mandant 131*/
@include erstelle-mandanten('jini'); /* Mandant 132*/
@include erstelle-mandanten('jibb'); /* Mandant 133*/
@include erstelle-mandanten('jimv'); /* Mandant 134*/
@include erstelle-mandanten('unsf'); /* Mandant 135*/
@include erstelle-mandanten('rsdv'); /* Mandant 136*/
@include erstelle-mandanten('unso'); /* Mandant 137*/
@include erstelle-mandanten('swhh'); /* Mandant 138*/
@include erstelle-mandanten('rarp'); /* Mandant 139*/
@include erstelle-mandanten('rahe'); /* Mandant 140*/
@include erstelle-mandanten('unve'); /* Mandant 141*/
@include erstelle-mandanten('xbbw'); /* Mandant 142*/
@include erstelle-mandanten('xbwb'); /* Mandant 143*/
@include erstelle-mandanten('xbsb'); /* Mandant 144*/
@include erstelle-mandanten('xbbd'); /* Mandant 145*/
@include erstelle-mandanten('taau'); /* Mandant 146*/
@include erstelle-mandanten('tabl'); /* Mandant 147*/
@include erstelle-mandanten('takt'); /* Mandant 148*/
@include erstelle-mandanten('tano'); /* Mandant 149*/
@include erstelle-mandanten('taoo'); /* Mandant 150*/
@include erstelle-mandanten('tasg'); /* Mandant 151*/
@include erstelle-mandanten('tasm'); /* Mandant 152*/
@include erstelle-mandanten('tati'); /* Mandant 153*/
@include erstelle-mandanten('tava'); /* Mandant 154*/
@include erstelle-mandanten('tawi'); /* Mandant 155*/
@include erstelle-mandanten('hbww'); /* Mandant 156*/
@include erstelle-mandanten('shdv'); /* Mandant 200*/
@include erstelle-mandanten('shbd'); /* Mandant 201*/
@include erstelle-mandanten('shbe'); /* Mandant 202*/
@include erstelle-mandanten('shbb'); /* Mandant 203*/
@include erstelle-mandanten('shba'); /* Mandant 204*/
@include erstelle-mandanten('shhh'); /* Mandant 205*/
@include erstelle-mandanten('shhe'); /* Mandant 206*/
@include erstelle-mandanten('shmv'); /* Mandant 207*/
@include erstelle-mandanten('shnd'); /* Mandant 208*/
@include erstelle-mandanten('shni'); /* Mandant 209*/
@include erstelle-mandanten('shnw'); /* Mandant 210*/
@include erstelle-mandanten('shos'); /* Mandant 211*/
@include erstelle-mandanten('shpf'); /* Mandant 212*/
@include erstelle-mandanten('shrh'); /* Mandant 213*/
@include erstelle-mandanten('shsl'); /* Mandant 214*/
@include erstelle-mandanten('shsb'); /* Mandant 215*/
@include erstelle-mandanten('shsn'); /* Mandant 216*/
@include erstelle-mandanten('shst'); /* Mandant 217*/
@include erstelle-mandanten('shth'); /* Mandant 218*/
@include erstelle-mandanten('shwf'); /* Mandant 219*/
@include erstelle-mandanten('shwb'); /* Mandant 220*/
@include erstelle-mandanten('xbst'); /* Mandant 300*/
@include erstelle-mandanten('bast'); /* Mandant 301*/
@include erstelle-mandanten('bbst'); /* Mandant 302*/
@include erstelle-mandanten('brst'); /* Mandant 303*/
@include erstelle-mandanten('bist'); /* Mandant 304*/
@include erstelle-mandanten('mbst'); /* Mandant 305*/
@include erstelle-mandanten('bost'); /* Mandant 306*/
@include erstelle-mandanten('esst'); /* Mandant 307*/
@include erstelle-mandanten('fest'); /* Mandant 308*/
@include erstelle-mandanten('fbst'); /* Mandant 309*/
@include erstelle-mandanten('gsst'); /* Mandant 310*/
@include erstelle-mandanten('ghst'); /* Mandant 311*/
@include erstelle-mandanten('hbst'); /* Mandant 312*/
@include erstelle-mandanten('host'); /* Mandant 313*/
@include erstelle-mandanten('just'); /* Mandant 314*/
@include erstelle-mandanten('ktst'); /* Mandant 315*/
@include erstelle-mandanten('kest'); /* Mandant 316*/
@include erstelle-mandanten('dlst'); /* Mandant 317*/
@include erstelle-mandanten('slst'); /* Mandant 318*/
@include erstelle-mandanten('mfst'); /* Mandant 319*/
@include erstelle-mandanten('msst'); /* Mandant 320*/
@include erstelle-mandanten('rfst'); /* Mandant 321*/
@include erstelle-mandanten('rest'); /* Mandant 322*/
@include erstelle-mandanten('rist'); /* Mandant 323*/
@include erstelle-mandanten('rost'); /* Mandant 324*/
@include erstelle-mandanten('rsst'); /* Mandant 325*/
@include erstelle-mandanten('rust'); /* Mandant 326*/
@include erstelle-mandanten('scst'); /* Mandant 327*/
@include erstelle-mandanten('swst'); /* Mandant 329*/
@include erstelle-mandanten('smst'); /* Mandant 330*/
@include erstelle-mandanten('skst'); /* Mandant 331*/
@include erstelle-mandanten('twst'); /* Mandant 332*/
@include erstelle-mandanten('tzst'); /* Mandant 333*/
@include erstelle-mandanten('stst'); /* Mandant 334*/
@include erstelle-mandanten('test'); /* Mandant 335*/
@include erstelle-mandanten('ttst'); /* Mandant 336*/
@include erstelle-mandanten('tust'); /* Mandant 337*/
@include erstelle-mandanten('vbst'); /* Mandant 338*/
@include erstelle-mandanten('wast'); /* Mandant 339*/
@include erstelle-mandanten('sest'); /* Mandant 340*/
@include erstelle-mandanten('pkst'); /* Mandant 341*/
@include erstelle-mandanten('fust'); /* Mandant 342*/
@include erstelle-mandanten('afst'); /* Mandant 343*/
@include erstelle-mandanten('spst'); /* Mandant 344*/
@include erstelle-mandanten('csst'); /* Mandant 345*/
@include erstelle-mandanten('bgst'); /* Mandant 346*/
@include erstelle-mandanten('sost'); /* Mandant 347*/
@include erstelle-mandanten('gmst'); /* Mandant 350*/
@include erstelle-mandanten('bsst'); /* Mandant 351*/
@include erstelle-mandanten('bcst'); /* Mandant 353*/
@include erstelle-mandanten('dsst'); /* Mandant 357*/
@include erstelle-mandanten('rkst'); /* Mandant 360*/
@include erstelle-mandanten('rqst'); /* Mandant 361*/
@include erstelle-mandanten('rbst'); /* Mandant 362*/
@include erstelle-mandanten('sxst'); /* Mandant 365*/
@include erstelle-mandanten('sqst'); /* Mandant 366*/
@include erstelle-mandanten('aist'); /* Mandant 368*/
@include erstelle-mandanten('sast'); /* Mandant 369*/
@include erstelle-mandanten('gost'); /* Mandant 370*/
@include erstelle-mandanten('kbst'); /* Mandant 372*/
@include erstelle-mandanten('crst'); /* Mandant 373*/
@include erstelle-mandanten('gxst'); /* Mandant 374*/
@include erstelle-mandanten('xlst'); /* Mandant 375*/
@include erstelle-mandanten('fwst'); /* Mandant 376*/
@include erstelle-mandanten('fist'); /* Mandant 377*/
@include erstelle-mandanten('kfst'); /* Mandant 379*/
@include erstelle-mandanten('mtst'); /* Mandant 381*/
@include erstelle-mandanten('xkst'); /* Mandant 386*/
@include erstelle-mandanten('xwst'); /* Mandant 387*/
@include erstelle-mandanten('hust'); /* Mandant 388*/
@include erstelle-mandanten('xost'); /* Mandant 389*/
@include erstelle-mandanten('kost'); /* Mandant 390*/
@include erstelle-mandanten('hsst'); /* Mandant 391*/
@include erstelle-mandanten('xfst'); /* Mandant 392*/
@include erstelle-mandanten('anst'); /* Mandant 393*/
@include erstelle-mandanten('dbst'); /* Mandant 394*/
@include erstelle-mandanten('nnst'); /* Mandant 399*/
@include erstelle-mandanten('yist'); /* Mandant 408*/
@include erstelle-mandanten('yrst'); /* Mandant 417*/
@include erstelle-mandanten('ytst'); /* Mandant 419*/
@include erstelle-mandanten('yust'); /* Mandant 420*/
@include erstelle-mandanten('yvst'); /* Mandant 421*/
@include erstelle-mandanten('ywst'); /* Mandant 422*/
@include erstelle-mandanten('yxst'); /* Mandant 423*/
@include erstelle-mandanten('shst'); /* Mandant 430*/
@include erstelle-mandanten('tast'); /* Mandant 432*/
@include erstelle-mandanten('kast'); /* Mandant 433*/
@include erstelle-mandanten('dsst'); /* Mandant 435*/
@include erstelle-mandanten('xbni'); /* Mandant 500*/
@include erstelle-mandanten('aini'); /* Mandant 501*/
@include erstelle-mandanten('afni'); /* Mandant 502*/
@include erstelle-mandanten('bani'); /* Mandant 503*/
@include erstelle-mandanten('bgni'); /* Mandant 504*/
@include erstelle-mandanten('bsni'); /* Mandant 505*/
@include erstelle-mandanten('bbni'); /* Mandant 506*/
@include erstelle-mandanten('brni'); /* Mandant 507*/
@include erstelle-mandanten('bini'); /* Mandant 508*/
@include erstelle-mandanten('roni'); /* Mandant 509*/
@include erstelle-mandanten('boni'); /* Mandant 510*/
@include erstelle-mandanten('esni'); /* Mandant 511*/
@include erstelle-mandanten('feni'); /* Mandant 512*/
@include erstelle-mandanten('fbni'); /* Mandant 513*/
@include erstelle-mandanten('gsni'); /* Mandant 514*/
@include erstelle-mandanten('ghni'); /* Mandant 515*/
@include erstelle-mandanten('goni'); /* Mandant 516*/
@include erstelle-mandanten('honi'); /* Mandant 517*/
@include erstelle-mandanten('jjni'); /* Mandant 518*/
@include erstelle-mandanten('juni'); /* Mandant 519*/
@include erstelle-mandanten('ktni'); /* Mandant 520*/
@include erstelle-mandanten('keni'); /* Mandant 521*/
@include erstelle-mandanten('slni'); /* Mandant 522*/
@include erstelle-mandanten('mfni'); /* Mandant 523*/
@include erstelle-mandanten('mbni'); /* Mandant 524*/
@include erstelle-mandanten('msni'); /* Mandant 525*/
@include erstelle-mandanten('reni'); /* Mandant 526*/
@include erstelle-mandanten('rkni'); /* Mandant 527*/
@include erstelle-mandanten('rini'); /* Mandant 528*/
@include erstelle-mandanten('rsni'); /* Mandant 529*/
@include erstelle-mandanten('runi'); /* Mandant 530*/
@include erstelle-mandanten('rbni'); /* Mandant 531*/
@include erstelle-mandanten('scni'); /* Mandant 532*/
@include erstelle-mandanten('suni'); /* Mandant 533*/
@include erstelle-mandanten('seni'); /* Mandant 534*/
@include erstelle-mandanten('skni'); /* Mandant 535*/
@include erstelle-mandanten('sqni'); /* Mandant 536*/
@include erstelle-mandanten('twni'); /* Mandant 537*/
@include erstelle-mandanten('stni'); /* Mandant 538*/
@include erstelle-mandanten('teni'); /* Mandant 539*/
@include erstelle-mandanten('ttni'); /* Mandant 540*/
@include erstelle-mandanten('tuni'); /* Mandant 541*/
@include erstelle-mandanten('vbni'); /* Mandant 542*/
@include erstelle-mandanten('spni'); /* Mandant 543*/
@include erstelle-mandanten('wwni'); /* Mandant 544*/
@include erstelle-mandanten('dlni'); /* Mandant 545*/
@include erstelle-mandanten('swni'); /* Mandant 546*/
@include erstelle-mandanten('dsni'); /* Mandant 547*/
@include erstelle-mandanten('funi'); /* Mandant 548*/
@include erstelle-mandanten('kbni'); /* Mandant 549*/
@include erstelle-mandanten('csni'); /* Mandant 550*/
@include erstelle-mandanten('bnni'); /* Mandant 551*/
/** GENERATE MANDANTEN END */

$mobile: "(max-width: #{$screen-xs-max})";
$tablet: "(min-width: #{$screen-sm-min}) and (max-width: #{$screen-sm-max})";
$desktop: "(min-width: #{$screen-md-min})";

#paulOverlay {
  background: url('../images/icons/paulOverlay.gif') center center no-repeat;
  min-height: 50px;
}

#luposOverlay {
  background: url('../images/icons/paulOverlay.gif') center center no-repeat;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #000;
  opacity: .2;
  position: fixed;
  left: 0px;
  top: 0px;
  text-align: center;
  padding-top: 30%;
  z-index: 999000;
}

#sreOverlay {
  background: url('../images/icons/ajax-loader-bert.gif') center center no-repeat;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #000;
  opacity: .5;
  position: fixed;
  left: 0px;
  top: 0px;
  text-align: center;
  padding-top: 30%;
  z-index: 999000;
}

/* Michaels Bildschirm gesperrt Overlay, wenn Sitzung abgelaufen ist */
#layer {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-image: url('/images/transparent.png');
  background-repeat: repeat;
  z-index: 10;
  display: none;
}

.layer_innerInfoBlock {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 220px;
  width: 800px;
  margin: -110px -400px;
  text-align: center;
}

body, html {
  height: 100%;
}

body {
  background-repeat: no-repeat;
  background-color: #EAE8E9;
  font-family: verdana;
  font-size: 12px;
  overflow-y: scroll;
}

h1, h2, h3, h4, h5 {
  font-family: verdana
}

fieldset {
  border: 0px #9A9A9A solid;
}

input[type="text"] {
}

input[type="password"] {
}

input[type="submit"], input[type="button"] {
  background: #f0f0f0;
  border: 1px solid #ccc;
  margin: 0px;
}

input[type="submit"][disabled=disabled].prevButton, input[type="button"][disabled=disabled].prevButton, input[type="submit"][disabled=disabled].nextButton, input[type="button"][disabled=disabled].nextButton {
  background-image: url(../imgPhoenix/button_klein_disabled.png) !important;
}

input[type="submit"].active {
  background: #fff;
}

input.width30 {
  width: 30px;
}

input.width50 {
  width: 50px;
}

input.width100, select.width100 {
  width: 100px;
}

input.width150, select.width150 {
  width: 150px;
}

input.width200, select.width200 {
  width: 200px;
}

input.width300, select.width300 {
  width: 300px;
}

input.width400, select.width400 {
  width: 400px;
}

tr.hover {
  background-color: #F9BC02;
}

.table {
  tr.fat-row {
    td {
      vertical-align: middle;
      height: 36px;
    }
  }
}

.red {
  color: red;
}

.btn-danger-revert {
  color: #d9534f;
  background-color: #ffffff;
  border-color: #ffffff;
}

#page {
  width: 100%;
  max-width: 1400px;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  color: #333;
  font-family: verdana;
  font-size: 12px;
  //overflow: hidden;
  //min-height: 100%; //TODO-tobias.maxham: Should we remove that line???
}

#page #content a:hover {
  color: $pii-link-color-hover;
  text-decoration: underline;
}

#top {
  background: #fff;
  behavior: url(../lib/pie/PIE.htc);
  -moz-border-radius: 20px 20px 0 0;
  -webkit-border-radius: 20px 20px 0 0;
  border-radius: 20px 20px 0 0;
  border-bottom: 1px solid #ccc;
  padding: 15px 5px;
  margin-bottom: 0px;
  margin-top: 15px;
}

#top #logo {
  background-repeat: no-repeat;
  height: 70px;
}

.nav-breadcrumb {
  font-size: 12px !important;
  color: $pii-link-color;
  padding-left: 0 !important;

  a {
    color: $pii-link-color;

    &:hover {
      color: $pii-link-color-hover
    }
  }
}

.std-top-navbar {
  margin-bottom: 0px;
  border-bottom: 1px solid #ccc;
  behavior: url(../lib/pie/PIE.htc);
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  min-height: 0px;
  background-color: #EEEEEE;
}

.std-top-navbar .navbar-collapse {
  padding: 0 5px;
}

.std-top-navbar li a {
  padding-top: 7px;
  padding-bottom: 10px;
  text-decoration: none !important;
  color: #333 !important;
}

.std-top-navbar li {
  margin-bottom: -1px;
}

.std-top-navbar li.active {
  border-left: 1px solid #cdcdcd;
  border-right: 1px solid #cdcdcd;
  border-bottom: 1px solid #fff;
}

.std-top-navbar li.active a {
  background-color: #ffffff !important;
  font-weight: bold;
  color: $pii-link-color-menu !important;
}

.std-top-navbar li a:hover {
  color: $pii-link-color-hover !important;
}

.tabs {
  padding: 0;
  margin-bottom: 0px;
}

.tab, .tabSelected, .tabH4A, .tabH4ASelected {
  behavior: url(../lib/pie/PIE.htc);
  /*padding: 15px 20px 15px 60px;*/
  -moz-border-radius: 10px 10px 0 0;
  -webkit-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
  background: #FAE092;
  float: left;
  margin-right: 2px;
  transition: background-color .8s;
  border: 1px solid #ccc;
}

.tab a, .tabSelected a, .tabH4A a, .tabH4ASelected a {
  padding: 8px;
  display: block;
  text-decoration: none !important;
}

.tab a:hover, .tabSelected a:hover, .tabH4A a:hover, .tabH4ASelected a:hover {
  color: #000000 !important;
}

.tab:hover {
  background: #F9BC02
}

.tabH4A:hover {
  background: #ED821F
}

.tabSelected, .tabH4ASelected {
  background: #fff;
  border-bottom: 1px solid #ffffff;
}

.subTab, .subTabH4A, .subTabSelected, .subTabH4ASelected {
  float: left;
}

.subTab a, .subTabH4A a, .subTabSelected a, .subTabH4ASelected a {
  padding: 10px 10px 10px 10px;
  display: block;
  text-decoration: none !important;
}

.subTabSelected, .subTabH4ASelected {
  font-weight: bold;
}

.bemerkungFeld {
  font-family: Arial;
}

#profile {
  position: absolute;
  top: 79px;
  right: -11px;
  width: 300px;
  height: 130px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  display: none;
  z-index: 10000;
  text-align: left;
}

#subnavigation {
  background: #fff;
}

#subnavigation a {
  color: #333
}

#subnavigation a:hover {
  color: #D2322D;
}

#subnavigation .item-list-all {
  padding: 0px 5px;
  border-bottom: 1px solid #ccc;
}

#editorTab {

  background-color: #F7F5F5;
}

#mainArea {
  behavior: url(../lib/pie/PIE.htc);
  -moz-border-radius: 20px 20px 0 0;
  -webkit-border-radius: 20px 20px 0 0;
  border-radius: 20px 20px 0 0;
  margin-bottom: 30px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.31) 0px 0px 10px;
  background: #FFF;
}

#content {
  background: #fff;
  padding: 0px;
}

#content h2 {

  font-size: 16px;
  margin: 0px;
}

#content fieldset {
  padding: 1rem;
}

#content .odd {
  background: #EEEEEE;
  padding: 20px 15px 0 15px;
}

#content .even {
  background: #fff;
  padding: 20px 15px 0 15px;
}

table, tr, td {
  font-size: 12px;
  border-color: #ccc;
}

.left {
  float: left;
}

.right {
  float: right;
}

.clear {
  clear: both;
}

.error {
  color: red;
  font-weight: bold;
}

input.locked {
  background-color: #ebebe3;
}

.searchList {
  border-collapse: collapse;
  width: 100%;
  border: none;
}

.searchList .searchlist-searchpart {
  border-bottom: 1px solid #ccc;
  padding: 10px 15px;
}

.control-label-normal {
  font-weight: normal;
}

.searchList .searchlist-infopart {
  padding: 10px 0 0 2px;
}

.searchList .searchlist-navi {
  padding: 0 15px 10px 15px;
  border-bottom: 1px solid #ccc;
}

.searchList .searchlist-navi .btn-group {
  padding: 10px 20px 0 0;
}

.searchList .searchlist-navi .fetchlimit-field {
  float: left;
  height: 30px;
  width: 40px;
  border: 1px solid #cccccc;
  border-left: 0px;
  border-right: 0px;
  text-align: center;
}

.searchList .searchlist-list table, .searchList .searchlist-list-view table {
  margin-bottom: 0px;
}

.searchList .searchlist-list, .searchList .searchlist-list-view {
  border-bottom: 1px solid #cccccc;
}

.searchList .searchlist-list th, .searchList .searchlist-list-view th {
  border-top: 0;
}

.searchList .searchlist-list td {
  padding: 4px 6px 4px 6px;
  cursor: pointer;
}

.searchList .searchlist-list-view td {
  padding: 4px;
}

.searchList .listItem {
  padding: 4px 5px 0 15px !important;
  width: 33px;
}

.searchList .listItem label {
  padding: 2px 0 0 0;
  margin: 0px !important;
}

.searchList .listItem input {
  padding: 0;
  margin: 0px !important;
}

.editorPart-header, .searchList .searchlist-searchpart {
  background: #EEEEEE;
}

.editorPart-header {
  border-bottom: 1px solid #ccc;
  padding: 5px 15px;

  &.editorPart-header-in-tab {
    margin-top: 15px;
    border-top: 1px solid #ccc;
  }

}

.editor-header-spacer {
  padding-top: 4px
}

.entity-header {
  background: #F5F5F5;
  border-bottom: 1px #ccc solid;
  padding: 2px 0;
}

.entity-header-part {
  margin: 0;
  padding: 5px 0px 0 0px;
  border-bottom: 1px #ccc solid
}

.std-edit-buttons {
  margin-top: 15px;
}

#footer {
  margin: 0 auto;
  padding: 0.75rem 1rem 0.625rem 1rem;
  color: #333;
  font-family: verdana, serif;
  font-size: 12px;
  vertical-align: middle;
  background: #fff;
}

#footer img {
  vertical-align: middle;
}

div.errorlist, div.infolist {
  margin: 1rem;
}

.evenlistitem {
  background-color: #FFFFFF
}

.oddlistitem {
  background-color: #f5f5f5
}

.wrongFormat {
  background-color: orange;
}

th {
  text-align: left;
  padding: 0 0 10px 3px;
}

.passbild {
  width: 80px;
}

.ui-autocomplete-loading {
  background: white url('../lib/jqueryui/ui-anim_basic_16x16.gif') right center no-repeat;
}

.ui-autocomplete {
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  display: none;
}

.buttonUp, .buttonDown {
  background: transparent url(../imgPhoenix/arrows_up.png) !important;
  border: 0 !important;
  height: 25px;
  width: 25px;
  cursor: pointer;
  color: transparent;
}

.buttonDown {
  background: transparent url(../imgPhoenix/arrows_down.png) !important;
}

.buttonDown:disabled, .buttonUp:disabled {
  opacity: 0.5
}

.holder {
  padding: 10px 10px;
  background-color: #EEEEEE;
}

.std-holder-border {
  padding: 10px;
}

.std-holder-finish {
  margin: 0 0 20px 0;
  border-bottom: 1px solid #ccc;
}

.holder-fieldset input[type="radio"], .holder-fieldset input[type="checkbox"] {
  margin: 2px 5px 0 -20px;
}

.holder-fieldset {
  .radio-inline, .checkbox-inline {
    min-height: 27px;

    label {
      display: inline;
      font-weight: normal;
      cursor: pointer;
    }
  }

  .radio, .checkbox {
    min-height: 27px;
    padding-top: 5px;
    margin: 0;

    label.control-label {
      padding-top: 0px;
      font-weight: bold;
    }

    label.control-label-normal {
      @extend .control-label-normal;
    }
  }
}

.radioNoBreak {
  float: left;
  margin-right: 15px !important;
}

.holder-row {
  .std-field {
    margin-bottom: 10px;
  }

  &.holder-row-last {
    margin-bottom: -8px;

    .std-field {
      margin-bottom: 0px;
    }
  }
}

#tabs {
  border: 0px;
  background: #ffffff;
}

#tabs .ui-widget-header {
  border: 0px;
}

#tabs .ui-tabs-panel {
  background: #fff;
  padding: 0px;
  border: 1px solid #ccc;
}

#tabs .ui-tabs-nav {
  padding: 0;
  border-spacing: 0px;
}

#tabs .ui-tabs-nav {
  padding: 0;
  border-spacing: 0;
}

@media only screen and (min-width: 992px) {
  #tabs .ui-tabs-nav {
    margin-bottom: -1px;
    display: flex;
    flex-wrap: wrap;
  }
}

#tabs .ui-state-default a:focus {
  outline: 0px;
}

#tabs .ui-corner-bottom {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

#tabs .ui-state-default a, #tabs .ui-corner-top a {
  padding: 0px;
}

#tabs .ui-state-default, #tabs .ui-corner-top {
  display: flex;
  behavior: url(../lib/pie/PIE.htc);
  -moz-border-radius: 0px 0px 0 0;
  -webkit-border-radius: 0px 0px 0 0;
  border-radius: 0px 0px 0 0;
  background: #FAE092;
  float: none;
  border: 1px solid #CCCCCC;
  transition: background-color .8s;
  margin: 0;

  &:hover {
    background: #F9BC02;
  }
}

#tabs .ui-tabs-active, #tabs .ui-state-active, #tabs .ui-tabs-active:hover, #tabs .ui-state-active:hover {
  background: #fff;
  border: 1px solid #CCCCCC;
}

#tabs .ui-tabs-active a {
  color: #000000;
}

#page .ui-widget, #page .ui-widget input, #page .ui-widget textarea, #page .ui-widget select {
  font-family: verdana;
  font-size: 12px;
}

#tabs .ui-state-default a, #tabs .ui-state-default a:link, #tabs .ui-state-default a:visited {
  color: #000;
  text-decoration: none;
  font-weight: normal;
}

#tabs .tabs-toolbar, #tabs .tabs-content {
  padding: 15px;
}

.dropdown-menu-content {
  background: #ffffff;
}

.dropdown-menu-content li {
  float: none;
  display: block;
}

.dropdown-menu-content a:hover, .dropdown-menu-content a {
  background-color: transparent !important;
  padding: 4px !important;
  text-decoration: none !important;
  color: #333333 !important;
}

#dropdownMenu1 {
  margin-bottom: 5px;
}

@media #{$desktop} {
  .pull-right-md {
    float: right !important;
  }

  .text-right-md {
    text-align: right !important;
  }

  .dropdown-menu-content {
    position: relative;
    top: 100%;
    left: 0;
    z-index: 1;
    display: inline-block;
    float: none;
    min-width: 0px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 12px;
    list-style: none;
    background-color: transparent;
    border: 0px solid #ccc;
    border: 0px solid rgba(0, 0, 0, 0.15);
    border-radius: 0px;
    -webkit-box-shadow: 0 0px 0px rgba(0, 0, 0, 0.175);
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
  }

  #tabs .ui-state-default, #tabs .ui-corner-top {
    behavior: url(../lib/pie/PIE.htc);
    -moz-border-radius: 10px 10px 0 0;
    -webkit-border-radius: 10px 10px 0 0;
    border-radius: 10px 10px 0 0;
    background: #FAE092;
    margin-right: 2px;
    border: 1px solid #CCCCCC;
    transition: background-color .8s;
  }

  #tabs .ui-tabs-active, #tabs .ui-state-active, #tabs .ui-tabs-active:hover, #tabs .ui-state-active:hover {
    background: #fff;
    border: 1px solid #CCCCCC;
    border-bottom: 1px solid #fff;
  }
}

.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
  font-family: inherit;
  font-size: 12px;
}

.navbar-default .navbar-brand {
  padding: 15px 20px;
  font-size: 15px;
}

@media #{$tablet} {
  .sm-padding {
    padding-bottom: 10px;
  }

  .text-right-sm {
    text-align: right;
  }
}

@media #{$mobile} {
  .xs-padding {
    padding-bottom: 10px;
  }
}

@media #{$desktop} {
  .navbar-default .navbar-brand {
    padding: 7px 15px 0px 15px;
  }
}

.std-info-boxen {
  position: absolute;
  right: -1px;
  top: -13px;
  z-index: 99;

  .std-info-btn {
    float: right;
    width: 33px;
    border: 1px solid #ccc;
    text-align: center;
    padding: 2px 5px 5px 5px;
    background: #FFFFFF;
    behavior: url(../lib/pie/PIE.htc);
    border-radius: 0px 0px 7px 7px;
    box-shadow: rgba(0, 0, 0, 0.23) 0px 1px 2px;

    &.std-info-btn-left {
      behavior: url(../lib/pie/PIE.htc);
      border-radius: 0px 0px 0px 7px;
    }

    &.std-info-btn-center {
      behavior: url(../lib/pie/PIE.htc);
      border-radius: 0;
    }

    &.std-info-btn-right {
      behavior: url(../lib/pie/PIE.htc);
      border-radius: 0px 0px 7px 0px;
    }
  }
}

.std-file-icon {
  text-decoration: none;
  font-size: 14px;
  @extend .icon-file4;

  &.std-file-pdf {
    @extend .icon-file-pdf;
    color: #cd0a0a;
  }

  &.std-file-xls, &.std-file-xlsx {
    @extend .icon-file-excel;
    color: #008000;
  }

  &.std-file-jpg, &.std-file-jpeg, &.std-file-png, &.std-file-bmp, &.std-file-gif {
    @extend .icon-images;
    color: darkslategrey;
  }

  &.std-file-doc, &.std-file-docx {
    @extend .icon-file-word;
    color: blue;
  }
}

.xs-dummy {
  height: $input-height-smaller;
}

.std-spacer {
  margin-bottom: 15px;
}

.std-locked-permanent-field, .std-string-to-field {
  padding-bottom: 5px;
  padding-top: 5px;
}

.lp-btn-relegation-beide {
  font-size: 15px;
  padding: 3px 8px;
}

.color-danger {
  color: $btn-danger-bg
}

.color-success {
  color: $btn-success-bg
}

.color-info {
  color: $btn-info-bg
}

.color-warning {
  color: $btn-warning-bg
}

.std-link-without-underline {
  text-decoration: none;

  &:hover {
    text-decoration: none !important;
  }
}

.std-tooltip {
  &.std-tooltip-help {
    cursor: help;
  }
}

.std-tooltip-big {
  width: 500px;
}

.tooltip-inner {
  max-width: 500px;
}

.validationerror {
  border-color: $state-danger-text;
  @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, .075)); // Redeclare so transitions work
  &:focus {
    border-color: darken($state-danger-text, 10%);
    $shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px lighten($state-danger-text, 20%);
    @include box-shadow($shadow);
  }
}

.table-responsive {
  .table-td-vertical-center {
    td {
      vertical-align: middle;
    }
  }
}

.std-empty-result {
  padding: 15px
}

.label-xl {
  font-size: 100%;
}

a.std-search-button {
  cursor: pointer;
  color: $pii-link-color-hover;
}

.std-border {
  border: 1px $std-border-color solid;

  &.std-border-top {
    border-width: 0px;
    border-top-width: 1px;
  }

  &.std-border-bottom {
    border-width: 0px;
    border-bottom-width: 1px;
  }

  &.std-border-left {
    border-width: 0px;
    border-left-width: 1px;
  }

  &.std-border-right {
    border-width: 0px;
    border-right-width: 1px;
  }
}

.badge {
  &.badge-small {
    font-size: 10px;
  }
}

.inline {
  display: inline;
}

.holder-bg {
  background-color: #EEEEEE;
}

.panel-group .panel-heading-block {
  padding: 0;
}

.panel-group .panel-heading-block .panel-title > a {
  display: block;
  position: relative;
  padding: 10px 15px 10px 15px;

  &:hover, &:focus {
    text-decoration: none !important;
  }
}

.modal-open {
  overflow: hidden;
  padding-right: 17px;
}

.auslastung-gruppe- {
  text-decoration: underline;
  text-decoration-color: grey;
}

.auslastung-gruppe-0 {
  text-decoration: underline;
  text-decoration-color: grey;
}

.auslastung-gruppe-1 {
  text-decoration: underline;
  text-decoration-color: red;
}

.auslastung-gruppe-2 {
  text-decoration: underline;
  text-decoration-color: yellow;
}

.auslastung-gruppe-3 {
  text-decoration: underline;
  text-decoration-color: green;
}

.browseralert {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0 !important;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  transform: translateY(100%);
  transition: all 500ms ease-out;
  color: #ecf0f1;
  background: #212327;
}

.browseralert.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0%);
  transition-delay: 1000ms;
}

.browseralert a {
  text-decoration: underline
}

.browseralert .acceptbrowser {
  margin-left: 10px;
  vertical-align: baseline;
}

.ui-tabs .ui-tabs-nav li.ui-tabs-active {
  padding-bottom: 0 !important;
}

.btn-is-link {
  text-decoration: none !important;
  color: #333 !important;
}

.form-horizontal {
  @media #{$desktop} {
    .control-label-left {
      text-align: left;
    }
  }
}

.ui-widget-header {
  background-color: transparent !important;
}

@media (min-width: 1025px) {
  .rm-padding-right-md {
    padding-right: 0 !important
  }
}

@media (min-width: 1025px) {
  .rm-padding-left-md {
    padding-left: 0 !important
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .rm-padding-right-sm {
    padding-right: 0 !important
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .rm-padding-left-sm {
    padding-left: 0 !important
  }
}

@media (max-width: 767px) {
  .rm-padding-right-xs {
    padding-right: 0 !important
  }
}

@media (max-width: 767px) {
  .rm-padding-left-xs {
    padding-left: 0 !important
  }
}